<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="ui-button-multiple">
    <v-tooltip
      class="ui-button-multiple__tooltip"
      open-on-click
      :open-on-focus="false"
      :open-on-hover="false"
      v-model="showConfirmationTooltip"
      bottom
      :disabled="!buttonNeedConfirmation"
      color="#ffffff"
      max-width="300px"
    >
      <template v-slot:activator="{ on }">
        <button v-on="on" class="ui-button-multiple__button" @click="buttonNeedConfirmation ? false : executeAction()">
          {{ actionSelect.label }}
        </button>
      </template>
      <div class="ui-button-multiple__tooltip__content">
        <div class="tw-text-center">
          {{ $t('ui.buttonMultiple.confirmation.label', { name: actionSelect.label }) }}
        </div>
        <div class="ui-button-multiple__tooltip__content__buttons">
          <v-btn small text color="#449afd" @click="executeAction">
            {{ $t('button.continue') }}
          </v-btn>
          <v-btn depressed x-small outlined color="error" @click="showConfirmationTooltip = false">
            {{ $t('button.close') }}
          </v-btn>
        </div>
      </div>
    </v-tooltip>

    <v-divider class="ui-button-multiple__divider" vertical />
    <v-menu
      class="ui-button-multiple__menu"
      v-model="displayChoices"
      :close-on-content-click="false"
      origin="top right"
      transition="scale-transition"
      offset-y
      max-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="ui-button-multiple__menu__trigger"
          :class="{ 'ui-button-multiple__menu__trigger--active': displayChoices }"
          v-bind="attrs"
          v-on="on"
        >
          <div v-if="actionLoading" class="ui-button-multiple__menu__trigger__loader">
            <v-progress-circular size="20" width="3" indeterminate />
          </div>
          <v-icon v-else class="ui-button-multiple__menu__trigger__icon" dark>
            {{ icons.mdiMenuDown }}
          </v-icon>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in buttonsAvailables"
          :key="`button ${index}`"
          :value="item.action"
          @click="setButtonSelect(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
            <v-list-item-subtitle class="tw-whitespace-normal tw-text-xs">{{ item.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mdiMenuDown } from '@mdi/js'

export default {
  name: 'UiButtonMultiple',
  props: {
    actionLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonsAvailables: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiMenuDown,
      },
      actionSelect: null,
      displayChoices: false,
      showConfirmationTooltip: false,
    }
  },
  created() {
    this.actionSelect = this.buttonsAvailables[0]
  },
  computed: {
    buttonNeedConfirmation() {
      return this.actionSelect.needConfirmation === true
    },
  },
  methods: {
    executeAction() {
      this.showConfirmationTooltip = false
      this.$emit('execute', this.actionSelect.action)
    },
    openDropdown() {
      this.$refs.buttonMultiplesActions.onClick(this.$refs.buttonMultiplesActions)
    },
    setButtonSelect(item) {
      this.displayChoices = false
      this.actionSelect = item
    },
  },
}
</script>

<style lang="scss">
.ui-button-multiple {
  @apply tw-ml-4 tw-flex tw-flex-row tw-rounded;
  cursor: pointer;
  color: white;
  background-color: $blue-dodger;

  .v-icon.v-icon::after {
    background-color: unset;
  }

  &__tooltip {
    &__content {
      @apply tw-flex tw-flex-col;
      color: $black;
      pointer-events: auto;

      &__buttons {
        @apply tw-flex tw-flex-row tw-justify-center tw-gap-4 tw-my-4;
      }
    }
  }

  &__button {
    @apply tw-py-2 tw-px-4 tw-transition tw-duration-200 tw-rounded;

    &:hover {
      background-color: #5ea9ff;
    }
  }

  &__divider {
    border-color: #5ea9ff !important;
    margin: 0;
  }

  &__menu {
    &__trigger {
      @apply tw-px-2 tw-rounded tw-flex;
      background-color: $blue-dodger;
      width: 40px;

      &:hover,
      &--active {
        background-color: #5ea9ff;
      }

      &__loader {
        @apply tw-self-center tw-flex tw-justify-center;
      }
    }
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    @apply tw-py-2;
    background-color: $blue-dodger;
  }

  .v-input input {
    font-weight: 500;
    letter-spacing: 0.089em;
    color: white;
  }

  .v-input__slot,
  .v-input input {
    @apply tw-transition tw-duration-200;
    cursor: pointer !important;
  }

  .v-input input:hover {
    color: $black;
  }
  .v-input__append-inner:hover {
    .v-icon,
    .endpoints-list__banner-actions__button__divider {
      color: $black;
      border-color: $black;
    }
  }
}
</style>
