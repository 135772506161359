<template>
  <v-navigation-drawer
    class="endpoints-modal-manage"
    width="500px"
    :value="showDrawer"
    @input="toggleDrawer"
    @transitionend="initData"
    absolute
    right
    temporary
  >
    <v-subheader class="endpoints-modal-manage__title">
      <div v-if="isUpdateEndpoint">
        <strong>{{ `[${this.currentEndpoint.method}]` }}</strong>
        {{ this.currentEndpoint.path }}
      </div>
      <div v-else>
        {{ $t('endpoints.modal.title') }}
      </div>
    </v-subheader>
    <v-divider />
    <div class="endpoints-modal-manage__body">
      <UiContainer large with-actions>
        <form
          id="endpointUpdate"
          class="tw-flex tw-flex-col tw-justify-center"
          @submit.prevent="onSubmit"
          novalidate
          autocomplete="off"
        >
          <div class="tw-mb-6">
            <ui-switch
              class="tw-p-0"
              :label="$t('endpoints.modal.form.status.label')"
              :switch-status="endpointModel.status"
              :icon="icons.mdiListStatus"
              @onChange="endpointModel.status = !endpointModel.status"
            />
          </div>
          <div class="endpoints-modal-manage__stepper__content__grid">
            <ui-block id="pathLabel" class="required-asterisk" :label="$t('endpoints.modal.form.path.label')">
              <template v-slot:body>
                <v-text-field
                  v-model="endpointModel.path"
                  :disabled="isUpdateEndpoint"
                  id="path"
                  solo
                  flat
                  outlined
                  dense
                  :placeholder="$t('endpoints.modal.form.path.placeholder')"
                  :error-messages="simpleErrors('endpointModel', 'path')"
                />
              </template>
            </ui-block>
            <ui-block id="methodLabel" class="required-asterisk" :label="$t('endpoints.modal.form.method.label')">
              <template v-slot:body>
                <v-autocomplete
                  v-model="endpointModel.method"
                  :disabled="isUpdateEndpoint"
                  id="method"
                  solo
                  flat
                  outlined
                  dense
                  :items="methodsAvailables"
                  :placeholder="$t('endpoints.modal.form.method.placeholder')"
                  :error-messages="simpleErrors('endpointModel', 'method')"
                />
              </template>
            </ui-block>
            <ui-block id="profilesLabel" :label="$t('endpoints.modal.form.profiles.label')">
              <template v-slot:body>
                <v-autocomplete
                  v-model="endpointModel.profiles"
                  id="profiles"
                  multiple
                  clearable
                  small-chips
                  solo
                  flat
                  outlined
                  :items="profilesAvailables"
                  :placeholder="$t('endpoints.modal.form.profiles.placeholder')"
                />
              </template>
            </ui-block>
          </div>
        </form>
      </UiContainer>
    </div>
    <template v-slot:append>
      <v-divider />
      <div class="tw-pb-4 tw-pt-6 tw-text-center">
        <v-btn
          class="tw-text-white tw-px-8"
          :loading="saving"
          :disabled="saving"
          form="endpointUpdate"
          type="submit"
          color="#449afd"
        >
          {{ $t(`button.${isUpdateEndpoint ? 'save' : 'create'}`) }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mdiListStatus, mdiContentCopy } from '@mdi/js'

import ErrorsMixin from '@/mixins/errors.mixin'
import exceptionManage from '@/mixins/exceptionManage.mixin'
import { userLocales } from '@/config/userLocales.config'

import UiBlock from '@/components/UI/Block.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiContainer from '@/components/UI/Container.vue'

const initEndpointModel = () => ({
  path: null,
  method: null,
  profiles: null,
  status: true,
})

export default {
  name: 'EndpointsModalManage',
  components: {
    UiBlock,
    UiContainer,
    UiSwitch,
  },
  mixins: [validationMixin, ErrorsMixin, exceptionManage],
  props: {
    showDrawer: {
      type: Boolean,
      required: true,
    },
    currentEndpoint: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        mdiListStatus,
        mdiContentCopy,
      },
      userLocales,
      userName: '',
      saving: false,
      endpointModel: initEndpointModel(),
      methodsAvailables: ['POST', 'PUT', 'GET', 'DELETE', 'PATCH'],
      profilesAvailables: ['sa', 'agency', 'brand_admin', 'regional_admin', 'group_admin', 'location', '*', 'public'],
    }
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
    }),
    bannerTitle() {
      if (this.isUpdateEndpoint) return `[${this.currentEndpoint.method}]${this.currentEndpoint.path}`
      return this.$t('endpoints.create.title')
    },
    isUpdateEndpoint() {
      return Object.keys(this.currentEndpoint).length > 0
    },
  },
  methods: {
    ...mapActions({
      postEndpoint: 'endpoints/postEndpoint',
      putEndpoint: 'endpoints/putEndpoint',
    }),
    initData() {
      if (this.isUpdateEndpoint) {
        const { path, method, profiles, status } = this.currentEndpoint
        this.endpointModel = {
          ...this.endpointModel,
          path,
          method,
          profiles,
          status,
        }
        this.endpointLabel = path
      }
    },
    toggleDrawer(boolean) {
      this.$v.$reset()
      this.endpointModel = initEndpointModel()
      this.$emit('toggle', boolean)
    },
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        try {
          const { path, method } = this.endpointModel
          const query = [
            { key: 'path', value: path },
            { key: 'method', value: method },
          ]

          if (this.isUpdateEndpoint) {
            await this.putEndpoint({ query, body: this.endpointModel })
          } else {
            await this.postEndpoint({ query, body: this.endpointModel })
          }
          this.$emit('save')
          this.showNotificationSuccess()
          this.$router.push({ name: 'EndpointList' })
        } catch ({ response }) {
          this.showNotificationError()
        }
        this.toggleDrawer(false)
        this.saving = false
      }
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t(`notification.${this.isUpdateEndpoint ? 'update' : 'create'}.endpoint`, {
          name: this.endpointModel.path,
        }),
      })
      this.saving = false
    },
  },
  validations() {
    return {
      endpointModel: {
        method: {
          required,
        },
        path: {
          required,
        },
        status: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.endpoints-modal-manage {
  &__title {
    @apply tw-text-lg tw-my-2;
    color: $black;
  }

  &__body {
    @apply tw-px-0;

    &__stepper {
      @apply tw-my-0;
      background-color: inherit !important;

      &__content {
        background-color: $white;

        &__grid {
          @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-4 tw-w-full tw-my-4;
        }
      }
    }
  }
}
</style>
