<template>
  <div class="endpoints-list">
    <endpoints-modal-manage
      :show-drawer="showDrawer"
      :current-endpoint="currentEndpoint"
      @toggle="toggleDrawer"
      @save="loadEndpoints"
    />

    <v-navigation-drawer
      v-model="showJsonPreview"
      class="endpoints-list__json-preview"
      width="500px"
      absolute
      right
      temporary
    >
      <div class="endpoints-list__json-preview__content">
        <v-subheader class="endpoints-list__json-preview__content__header">
          {{ $t('endpoints.list.jsonPreview.title') }}
        </v-subheader>
        <v-divider />
        <div class="endpoints-list__json-preview__content__body">
          <v-textarea
            class="endpoints-list__json-preview__content__body__textarea"
            :value="jsonCacheBeautify"
            hide-details
            readonly
            solo
            flat
            outlined
            no-resize
          >
            <template v-slot:append>
              <v-btn class="endpoints-list__json-preview__content__body__icon-copy" @click="copyJson" icon>
                <v-icon>{{ icons.mdiContentCopy }}</v-icon>
              </v-btn>
            </template>
          </v-textarea>
        </div>
      </div>
    </v-navigation-drawer>

    <UiBanner fluid :title="$t('endpoints.list.name')">
      <template v-slot:actions>
        <div class="endpoints-list__banner-actions">
          <v-text-field
            v-model="search"
            :value="search"
            :prepend-inner-icon="`${icons.mdiMagnify}`"
            :label="$t('endpoints.list.search')"
            hide-details
            outlined
            solo
            flat
            dense
            clearable
            @input="searchInput"
          />

          <ui-button-multiple
            :buttons-availables="buttonsAvailables"
            :actionLoading="actionLoading"
            @execute="executeAction"
          />
        </div>
      </template>
    </UiBanner>

    <UiContainer no-padding>
      <div class="endpoints-list__table-container">
        <v-data-table
          style="cursor: pointer"
          class="endpoints-list__table-container__table"
          height="100%"
          item-key="id"
          fixed-header
          :loading="refreshing"
          :headers="headers"
          :items="endpoints"
          :options.sync="options"
          :search="search"
          :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
          @click:row="openModal"
        >
          <template v-slot:[`item.path`]="{ item }">
            <code class="tw-py-1 tw-px-2">{{ item.path }}</code>
          </template>
          <template v-slot:[`item.method`]="{ item }">
            <div class="tw-flex tw-gap-2">
              <v-chip :color="methodColor[item.method]" dark small label>
                {{ item.method }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.profiles`]="{ item }">
            <div class="endpoints-list__table-container__table__profiles">
              <div v-for="(profil, index) in limitedProfiles(item)" :key="`limitedProfiles ${index}`">
                <v-chip outlined color="#449afd" small label>
                  {{ profil }}
                </v-chip>
              </div>
              <v-tooltip
                v-if="item.profiles.length > 2"
                content-class="endpoints-list__table-container__table__profiles__tooltip__content"
                class="endpoints-list__table-container__table__profiles__tooltip"
                color="#fcfeff"
                right
                transition="fade-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="endpoints-list__table-container__table__profiles__tooltip__content__more"
                    v-bind="attrs"
                    v-on="on"
                  >
                    + {{ item.profiles.length - 2 }}
                  </div>
                </template>
                <div class="endpoints-list__table-container__table__profiles__tooltip__content__items">
                  <div class="tw-my-1" v-for="(profil, index) in remainProfiles(item)" :key="`remainProfiles ${index}`">
                    {{ profil }}
                  </div>
                </div>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip outlined v-if="item.status === true" color="success">
              <v-icon left>
                {{ icons.mdiCheckboxMarkedCircleOutline }}
              </v-icon>
              {{ $t('endpoints.list.active') }}
            </v-chip>
            <v-chip outlined v-else color="error">
              <v-icon left>
                {{ icons.mdiCloseCircleOutline }}
              </v-icon>
              {{ $t('endpoints.list.inactive') }}
            </v-chip>
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiCheckboxMarkedCircleOutline, mdiCloseCircleOutline, mdiPlus, mdiContentCopy } from '@mdi/js'

import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiButtonMultiple from '@/components/UI/ButtonMultiple.vue'
import EndpointsModalManage from '@/components/Endpoints/ModalManage.vue'

import exceptionManage from '@/mixins/exceptionManage.mixin'

import { debounce } from '@/utils/debounce.util'
import { copyToClipboard } from '@/utils/utilities.util'

export default {
  name: 'EndpointList',
  components: {
    UiBanner,
    UiContainer,
    UiButtonMultiple,
    EndpointsModalManage,
  },
  mixins: [exceptionManage],
  data: () => ({
    icons: {
      mdiMagnify,
      mdiCheckboxMarkedCircleOutline,
      mdiCloseCircleOutline,
      mdiPlus,
      mdiContentCopy,
    },
    methodColor: {
      GET: '#4CAF50',
      POST: '#449afd',
      PUT: '#fa9e0a',
      DELETE: '#F44336',
    },
    options: {
      itemsPerPage: 25,
    },
    search: '',
    refreshing: false,
    actionLoading: false,
    showDrawer: false,
    showJsonPreview: false,
    currentEndpoint: {},
  }),
  async created() {
    await this.loadEndpoints()
  },
  computed: {
    ...mapState({
      endpoints: state => state.endpoints.endpoints,
      jsonCache: state => state.endpoints.jsonCache,
    }),
    buttonsAvailables() {
      return [
        {
          action: 'goToCreate',
          label: this.$t('endpoints.list.create.label'),
          description: this.$t('endpoints.list.create.description'),
        },
        {
          action: 'getCache',
          label: this.$t('endpoints.list.getCache.label'),
          description: this.$t('endpoints.list.getCache.description'),
        },
        {
          action: 'regenerateCache',
          label: this.$t('endpoints.list.regenerate.label'),
          description: this.$t('endpoints.list.regenerate.description'),
          needConfirmation: true,
        },
      ]
    },
    headers() {
      return [
        {
          text: this.$t('endpoints.list.headers.id'),
          value: 'id',
          sortable: true,
        },
        {
          text: this.$t('endpoints.list.headers.method'),
          value: 'method',
          sortable: true,
        },
        {
          text: this.$t('endpoints.list.headers.path'),
          value: 'path',
          sortable: true,
        },
        {
          text: this.$t('endpoints.list.headers.profiles'),
          value: 'profiles',
          sortable: true,
          width: 'auto',
        },
        {
          text: this.$t('endpoints.list.headers.status'),
          value: 'status',
          sortable: true,
          width: 'auto',
        },
      ]
    },
    jsonCacheBeautify() {
      return JSON.stringify(this.jsonCache, null, 4)
    },
  },
  methods: {
    ...mapActions({
      getEndpoints: 'endpoints/getEndpoints',
      regenerateEndpointsCache: 'endpoints/regenerateCache',
      getEndpointsCache: 'endpoints/getCache',
      setCache: 'endpoints/setCache',
    }),
    executeAction(action) {
      this[action]()
    },
    limitedProfiles(item) {
      if (item.profiles[0].length) return item.profiles.slice(0, 2)
      return []
    },
    remainProfiles(item) {
      return item.profiles.slice(2, item.profiles.length)
    },
    goToCreate() {
      this.currentEndpoint = {}
      this.showDrawer = true
    },
    openModal(endpoint) {
      this.currentEndpoint = endpoint
      this.showDrawer = true
    },
    toggleDrawer(boolean) {
      this.showDrawer = boolean
    },
    async loadEndpoints() {
      this.refreshing = true
      await this.getEndpoints()
      this.refreshing = false
    },
    async getCache() {
      this.actionLoading = true
      await this.tryManageError(async () => {
        await this.getEndpointsCache()
        this.showJsonPreview = true
      }, false)
      this.actionLoading = false
    },
    async regenerateCache() {
      this.actionLoading = true
      await this.tryManageError(async () => {
        await this.regenerateEndpointsCache()
      }, this.$t('notification.success.regenerate'))
      this.actionLoading = false
    },
    async copyJson() {
      this.tryManageError(async () => {
        await copyToClipboard(this.jsonCacheBeautify)
      }, this.$t('notification.success.copy'))
    },
    searchInput: debounce(function () {
      if (this.options.page !== 1) {
        this.options.page = 1
      }
    }, 300),
  },
}
</script>

<style lang="scss">
.endpoints-list {
  &__json-preview {
    .v-input__control,
    .v-input__slot {
      height: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.endpoints-list {
  &__json-preview {
    &__content {
      @apply tw-flex tw-flex-col;
      height: 100%;

      &__header {
        @apply tw-text-lg tw-my-2;
        color: $black;
      }

      &__body {
        @apply tw-px-4 tw-py-6;
        height: 100%;

        &__textarea {
          height: 100%;
        }

        &__icon-copy {
          position: absolute;
          right: 32px;
        }
      }
    }
  }

  &__banner-actions {
    @apply tw-flex tw-items-center;
  }

  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;

      &__profiles {
        @apply tw-flex tw-flex-row tw-flex-nowrap tw-gap-2 tw-items-center;
        overflow: hidden;
        max-height: 48px;

        &__tooltip {
          &__content {
            padding: 8px 16px;
            color: $black-light;

            &__more {
              color: $blue-dodger;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
